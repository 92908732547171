@import-normalize;

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  background: #2D2D2D;
  color: #000;
  font-family: Roboto;
  font-size: 16px;
}

#root {
  height: 100%;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}
